import React from "react";
import styled from "styled-components";
import CustomDoubleTitle from "../../General/CustomDoubleTitle";
import { Element } from "react-scroll";
import { Button } from "antd";

const ServicesSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0;
`;

const ServicesSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const ServicesSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const ServicesContainer = styled.div`
  padding: 80px 0 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  row-gap: 50px;
  flex-wrap: wrap;

  /* @media only screen and (max-width: 700px) {
    flex-direction: column;
    gap: 50px;
  } */
`;

const EachServiceContainer = styled.div`
  position: relative;
  border: 1px solid #0b3e40;
  border-radius: ${(props) => (props.middle ? "16px" : "56px 16px 16px 56px")};
  flex: 0 0 32%;
  width: 32%;
  height: 310px;
  cursor: pointer;

  @media only screen and (max-width: 969px) {
    flex: 0 0 48%;
    width: 48%;
  }

  @media only screen and (max-width: 637px) {
    flex: 0 0 100%;
    width: 100%;
    height: 296px;
  }
`;

const EachServiceContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 30px 10px;
`;

const EachServiceTitle = styled.div`
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
  text-align: center;
  letter-spacing: 0.9599999785423279px;
  height: 50px;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 19.5px;
  }
`;

const EachServiceDesc = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  height: 110px;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 23.8px;
  }
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 22px;
  border-radius: 32px;
  padding: 6px;
  background-color: #000e14;
  border: 1px solid #0b3e40;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -20px;
`;

const IconImg = styled.img``;

const IntroButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  & .ant-btn-primary,
  & .ant-btn-primary:hover,
  & .ant-btn-primary:focus {
    padding: 13px 20px;
    background: linear-gradient(
      94.04deg,
      #0379ff 0%,
      #1e94ab 40.32%,
      #0379ff 87.36%
    ) !important;
    border: none !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    border-radius: 12px;
    height: 48px;
  }

  @media only screen and (max-width: 600px) {
    & .ant-btn-primary,
    & .ant-btn-primary:hover,
    & .ant-btn-primary:focus {
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      height: 40px;
    }
  }
`;

const IntroButton = styled(Button)`
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none; // Removes the default border

  @media only screen and (max-width: 600px) {
    width: 210px;
  }

  @media only screen and (max-width: 355px) {
    width: 195px;
  }
`;

const ServicesSection = () => {
  const EachServiceCmp = ({ data }) => {
    return (
      <EachServiceContainer
        middle={data?.middle}
        onClick={() => {
          window.open(`https://ai-gig.bles-software.com/${data?.url}`, "_self");
        }}
      >
        <EachServiceContentWrapper>
          <IconWrapper>
            <IconImg src={data.icon} alt={data.title} />
          </IconWrapper>
          <EachServiceTitle>{data.title}</EachServiceTitle>
          <EachServiceDesc>{data.desc}</EachServiceDesc>

          <IntroButtonContainer>
            <IntroButton
              type="primary"
              htmlType="submit"
              size="large"
              onClick={() => {
                window.open(
                  `https://ai-gig.bles-software.com/${data?.url}`,
                  "_self"
                );
              }}
            >
              Get Started
            </IntroButton>
          </IntroButtonContainer>
        </EachServiceContentWrapper>
      </EachServiceContainer>
    );
  };
  return (
    <Element name="services">
      {" "}
      <ServicesSectionContainer>
        <ServicesSectionInnerContainer>
          <ServicesSectionContentWrapper>
            <CustomDoubleTitle
              firstTitle={"SERVICES"}
              secondTitle={"What our clients get"}
            />

            <ServicesContainer>
              {[
                {
                  title: "Web Application",
                  desc: "High-performance web applications tailored to enhance user engagement and drive business growth.",
                  icon: "/assets/settings.svg",
                  url: "web-application",
                },
                {
                  middle: true,
                  title: "Desktop Application",
                  desc: "Robust desktop applications designed for seamless performance and user-friendly experiences",
                  icon: "/assets/clipboard.svg",
                  url: "desktop-application",
                },
                {
                  title: "API & Integrations",
                  desc: "Efficient API and integrations for seamless connectivity and enhanced system interoperability.",
                  icon: "/assets/success.svg",
                  url: "api-and-integrations",
                },
                {
                  title: "Mobile App Cross-Platform Development",
                  desc: "Unified mobile app solutions for consistent functionality across all major platforms.",
                  icon: "/assets/rocket.svg",
                  url: "mobile-application-development",
                },
                {
                  middle: true,
                  title: "AI Applications Development",
                  desc: "Cutting-edge AI applications to automate processes and deliver intelligent insights.",
                  icon: "/assets/user.svg",
                  url: "ai-application-development",
                },
                {
                  title: "AI Chatbot Development",
                  desc: "Intuitive AI chatbots enhancing customer interaction and streamlining support services.",
                  icon: "/assets/ai.svg",
                  url: "chat-application-development",
                },
              ].map((v, i) => {
                return <EachServiceCmp key={i} data={v} />;
              })}
            </ServicesContainer>
          </ServicesSectionContentWrapper>
        </ServicesSectionInnerContainer>
      </ServicesSectionContainer>
    </Element>
  );
};

export default ServicesSection;
