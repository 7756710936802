import React from "react";
import { Helmet } from "react-helmet-async";
import "./fiverr.css";

const FiverrCard = () => {
  return (
    <>
      <div
        itemscope
        itemtype="http://schema.org/Person"
        className="fiverr-seller-widget"
        style={{ display: "inline-block" }}
      >
        <a
          itemprop="url"
          href="https://www.fiverr.com/stas4000"
          rel="nofollow noreferrer"
          target="_blank"
          style={{ display: "inline-block" }}
        >
          <div
            className="fiverr-seller-content"
            id="fiverr-seller-widget-content-ac790726-da08-428e-96c2-0f65668af062"
            itemprop="contentURL"
            style={{ display: "none" }}
          ></div>
          <div id="fiverr-widget-seller-data" style={{ display: "none" }}>
            <div itemprop="name">stas4000</div>
            <div itemscope itemtype="http://schema.org/Organization">
              <span itemprop="name">Fiverr</span>
            </div>
            <div itemprop="jobtitle">Seller</div>
            <div itemprop="description">
              Welcome! I'm Stas Sorokin with over 9 years of experience in the
              tech industry, including work with leading organizations.
              Specializing in front-end, back-end, native, and mobile
              development, I've honed a diverse skill set to meet your unique
              needs. My record of 50+ positive reviews underscores commitment,
              quality, speed, and clean code. I believe in putting the client at
              the center of everything I do. From our initial consultation to
              the final delivery, you will get a high-standard service that
              exceeds your expectations. Let's collaborate together to turn your
              ideas into a digital reality!
            </div>
          </div>
        </a>
      </div>
      <Helmet>
        <script
          id="fiverr-seller-widget-script-ac790726-da08-428e-96c2-0f65668af062"
          data-config='{"category_name":"Programming \u0026 Tech"}'
          async="true"
          defer="true"
        >
          {`!(function () {
    function e() {
      try {
        if (
          ((d = document.getElementById(
            "fiverr-seller-widget-content-" + c
          )),
          (f = document.getElementById("fiverr-seller-widget-script-" + c)),
          !d)
        )
          return void (a = !1);
        var e = JSON.parse(f.getAttribute("data-config")),
          n = e.category_name,
          i = t(s, o, l),
          v =
            "<div class='crop'><img src='/assets/fiverr-hero.jpeg' class='fiverr-profile-img' style='width:180px;height:180px'></img>             <div class='overlay'></div></div>             <img src='https://d2nb1f6l8b7ky0.cloudfront.net/fiverr_icon.png' class='fiverr-icon'> </img>             <div class='fiverr-seller-text'> Seller </div>             <div class='fiverr-seller-category'> " +
            n +
            " </div>             <div class='fiverr-rating-stars' id='fiverr-rating-stars-" +
            c +
            "' style='display: none'> " +
            i +
            " </div>             <div class='check-gigs-btn'>Check out my Gigs</div>";
        (d.innerHTML = v), r();
        var u = d.parentNode;
        if ("undefined" != typeof u) {
          var g = u.href;
          "undefined" != typeof g && (u.href = g.split("?")[0]);
        }
      } catch (m) {
        console.log("Fiverr Badge Error: " + m.message);
      }
    }
    function t(e, t, r) {
      var n = "";
      if (5 > r) {
        for (i = 0; e > i; i++) n += "<i class='fa fa-star'></i>";
        for (
          t && (n += "<i class='fa fa-star-half-o'></i>"), i = 0;
          r > i;
          i++
        )
          n += "<i class='fa fa-star-o'></i>";
      }
      return n;
    }
    function r() {
      var e = document.getElementsByTagName("head")[0],
        t = Math.floor(1e3 * Math.random()) + 1;
      n(
        e,
        "https://d2nb1f6l8b7ky0.cloudfront.net/css/seller_widget.css?v=" +
          t.toString(),
        function () {
          d.style.display = "inline-block";
        }
      ),
        n(
          e,
          "//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css",
          function () {
            var e = document.getElementById("fiverr-rating-stars-" + c);
            e.style.display = "block";
          }
        );
    }
    function n(e, t, r) {
      var n = /Firefox/.test(navigator.userAgent);
      if (n) {
        var i = document.createElement("style");
        i.textContent = '@import "' + t + '"';
        var a = setInterval(function () {
          try {
            i.sheet.cssRules, r(), clearInterval(a);
          } catch (e) {}
        }, 10);
        e.appendChild(i);
      } else {
        var s = document.createElement("link");
        s.setAttribute("rel", "stylesheet"),
          s.setAttribute("type", "text/css"),
          s.setAttribute("href", t),
          (s.onload = function () {
            r();
          }),
          s.addEventListener &&
            s.addEventListener(
              "load",
              function () {
                r();
              },
              !1
            ),
          (s.onreadystatechange = function () {
            var e = s.readyState;
            ("loaded" === e || "complete" === e) &&
              ((s.onreadystatechange = null), r());
          });
        var o = document.styleSheets.length,
          l = setInterval(function () {
            document.styleSheets.length > o && (r(), clearInterval(l));
          }, 10);
        e.appendChild(s);
      }
    }
    var a = !0,
      s = 5,
      o = !1,
      l = 0,
      c = "ac790726-da08-428e-96c2-0f65668af062",
      d = null,
      f = null;
    document.addEventListener("DOMContentLoaded", function () {
      a || e();
    }),
      e();
  })();`}
        </script>
      </Helmet>
    </>
  );
};

export default FiverrCard;
