import React from "react";
import HeroSection from "../../components/Home/HeroSection";
import OperationSection from "../../components/Home/OperationSection";
import ServicesSection from "../../components/Home/ServicesSection/ServicesSection";
import FaqSection from "../../components/Home/FaqSection";
import Cta from "../../components/General/Cta";
import Footer from "../../components/General/Footer";
import Header from "../../components/General/Header";
import CompareSection from "../../components/Home/CompareSection";
import CaseStudySection from "../../components/Home/CaseStudySection";
import TestimonialSection from "../../components/Home/TestimonialSection";
import SubscriptionStep from "../../components/Home/SubscriptionStep";
import PricingSection from "../../components/Home/PricingSection";

const Home = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <OperationSection />
      <ServicesSection />
      <TestimonialSection />
      <CompareSection />
      <CaseStudySection />
      <SubscriptionStep />
      <PricingSection />
      <FaqSection />
      {/* <BrandPushCard /> */}
      <Cta
        bigTitle={"Get Started Today with a 30-Minute Intro Call"}
        smallTitle={
          "Schedule a Complimentary Consultation with Stas. No Obligation. Book Your Call Today."
        }
        buttonText={"Schedule a call with us →"}
      />
      <Footer />
    </>
  );
};

export default Home;
